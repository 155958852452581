import { BaseService } from '../';
import { Injectable } from '@angular/core';
import { InviteApiInterface } from './invite.api.interface';
import { TournamentApiInterface } from '../tournament';
import { TeamApiInterface } from '../team';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root'})
export class InviteService extends BaseService implements EntityServiceInterface {

  public get(invite_id: string): Observable<InviteApiInterface|null> {
    return this.getOne<InviteApiInterface>(EntityTypeEnum.Invite, invite_id);
  }

  public getAll(tournament?: TournamentApiInterface, team?: TeamApiInterface): Observable<InviteApiInterface[]|null> {
    return this.getMany<InviteApiInterface>(EntityTypeEnum.Invite);
  }

  public save(invite: InviteApiInterface): Observable<InviteApiInterface|null> {
    return this.post<InviteApiInterface>(EntityTypeEnum.Invite, invite);
  }

  public deleteItem(id: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Invite, id);
  }

  public remove(id: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Invite, id);
  }

}
