import { Injectable } from '@angular/core';
import { BaseService } from '../';
import { ContestantApiInterface } from './contestant.api.interface';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root' })
export class ContestantService extends BaseService implements EntityServiceInterface {

  public getAllTournament(tournamentId: string): Observable<ContestantApiInterface[]|null> {
    return this.getMany<ContestantApiInterface>(
      EntityTypeEnum.Contestants, [{ key: 'tournament_id', value: tournamentId }]
    );
  }

  public getAllTeam(teamId: string): Observable<ContestantApiInterface[]|null> {
    return this.getMany<ContestantApiInterface>(
      EntityTypeEnum.Contestants, [{ key: 'team_id', value: teamId }]
    );
  }

  public get(id: string): Observable<ContestantApiInterface|null> {
    return this.getOne<ContestantApiInterface>(EntityTypeEnum.Contestants, id);
  }

  public save(contestant: ContestantApiInterface): Observable<ContestantApiInterface|null> {
    return this.post<ContestantApiInterface>(EntityTypeEnum.Contestants, contestant);
  }

  public remove(contestantId: string, teamId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Contestants, contestantId, teamId);
  }

  public deleteItem(contestanId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Contestants, contestanId);
  }
}
