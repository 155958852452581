import { Pipe, PipeTransform } from '@angular/core';
import { EntityFieldMetaInterface } from '../interfaces';
import { EntityTypeEnum } from '../enums';

@Pipe({
  name: 'appFieldname',
  standalone: true
})
export class AppFieldnamePipe implements PipeTransform {

  transform(field: EntityFieldMetaInterface, entityType: EntityTypeEnum): string {
    return `entity.${entityType}.${field.fieldName}`;
  }
}
