export async function convertCsvToJson<T>(csvFile: File, expectedHeaders: string): Promise<T[]> {
    return new Promise((resolve, reject): void => {
        const reader: FileReader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target) {
                const csvData: string = event.target.result as string;
                const lines: string[] = csvData.split(/\r\n|\r|\n/).filter((line: string) => !!line);

                if (!lines.length) {
                    reject('CSV file is empty');
                    return;
                }

                const headers: string[] = lines.shift()?.split(';') || [];

                const error: string = getArrayDifferences(
                    expectedHeaders.split(';').filter((field: string) => !!field),
                    headers,
                );

                if (error) {
                    reject(error);
                }

                const jsonData: T[] = lines.map((line: string): T => {
                    const values: string[] = line.split(';');
                    const entry: { [key: string]: string | number } = {};
                    for (let index: number = 0; index < headers.length; index++) {
                        entry[headers[index]] = /^\d+$|^\d+[,.]\d+$/.test(values[index]) ? Number(values[index].replace(',','.')).toFixed(2) : values[index];
                    }
                    return entry as T;
                });

                resolve(jsonData);
            } else {
                reject('Failed to read CSV file');
            }
        };

        reader.onerror = () => {
            reject('Error reading CSV file');
        };

        reader.readAsText(csvFile);
    });
}

function getArrayDifferences(expected: string[], fromFile: string[]): string {
    const notInFile: string[] = expected.filter((element: string): boolean => !fromFile.includes(element));
    const unexpected: string[] = fromFile.filter((element: string): boolean => !expected.includes(element));
    return [
        notInFile.length ? `Expected columns not in file: ${notInFile.join(', ')}.` : null,
        unexpected.length ? `Unexpected columns in file: ${unexpected.join(', ')}.` : null,
    ].filter((error: string | null) => !!error).join(' ');
}
