export const countries: string[] = [
  'fr',
  'de',
  'gb',
  'nl',
  'pl',
  'pt',
  're',
  'it'
];
