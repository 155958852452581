import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityTypeEnum } from '../enums';
import { EntityInterface } from '../interfaces';

// noinspection JSIgnoredPromiseFromCall
@Injectable({ providedIn: 'root' })
export class NavigateService {

  static homeRoute = '/';
  static loginRoute = ['/', 'auth', 'login'];

  constructor(
    private router: Router
  ) {
  }

  get isHome(): boolean {
    return this.router.url === NavigateService.homeRoute;
  }

  public toHome(): void {
    this.router.navigate([NavigateService.homeRoute]);
  }

  public toLogin(): void {
    this.router.navigate(NavigateService.loginRoute);
  }

  navigateToEntity(entity: EntityInterface) {
    if (entity.type === EntityTypeEnum.Tournaments) {
      this.navigateTo(entity.id);
    } else {
      this.navigateTo(
        entity.tournament_id,
        entity.type,
        entity.id
      );
    }
  }

  navigateTo(tournamentId: string, typeToNavigateTo?: EntityTypeEnum, entityId?: string) {
    this.router.navigate(this.getTournamentsRoute(tournamentId, typeToNavigateTo, entityId));
  }

  getTournamentsRoute(tournamentId?: string, typeToNavigateTo?: EntityTypeEnum, entityId?: string): string[] {
    const route = [NavigateService.homeRoute, EntityTypeEnum.Tournaments];

    if (!tournamentId) {
      return route;
    }

    route.push(tournamentId);

    if (!typeToNavigateTo) {
      return route;
    }

    route.push(typeToNavigateTo.toString());
    if (entityId) {
      route.push(entityId);
    }

    return route;
  }

}
