import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[libIcon]'
})
export class IconDirective implements OnInit {
  @Input() libIcon!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'app-icon')
    this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', `url(assets/icons/${this.libIcon}.svg)`)
  }
}
