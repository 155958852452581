import { TournamentApiInterface } from './tournament.api.interface';
import { TournamentService } from './tournament.service';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

export const TournamentResolver: ResolveFn<TournamentApiInterface | null> = (
  route: ActivatedRouteSnapshot
): Observable<TournamentApiInterface | null> => {
  return inject(TournamentService).get(route.paramMap.get('tournamentId') || '');
};
