import { Injectable } from '@angular/core';
import { BaseService } from '../core';
import { ContestantBulkUploadApiInterface } from './contestant-bulk-upload-api.interface';
import { NEVER, Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root' })
export class ContestantBulkUploadService extends BaseService {

  static exampleCSVTournament = `country;first_name;last_name_prefix;last_name;email;date_of_birth;gender;weight;`;
  static exampleCSVTeam = `first_name;last_name_prefix;last_name;email;date_of_birth;gender;weight;`;

  public saveManyTournament(tournament_id: string, contestants: ContestantBulkUploadApiInterface[]): Observable<boolean> {
    return this.postMany(EntityTypeEnum.ContestantsBulkUpload, contestants, { key: 'tournament_id', value: tournament_id });
  }

  public saveManyTeam(team_id: string, contestants: ContestantBulkUploadApiInterface[]): Observable<boolean> {
    return this.postMany(EntityTypeEnum.ContestantsBulkUpload, contestants, { key: 'team_id', value: team_id });
  }

  public downloadExampleCsvTournament(): void {
    const blob = new Blob([ContestantBulkUploadService.exampleCSVTournament], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'contestants-tournament.csv');
  }

  public downloadExampleCsvTeam(): void {
    const blob = new Blob([ContestantBulkUploadService.exampleCSVTeam], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'contestants-team.csv');
  }

  deleteItem(id: string): Observable<boolean> {
    return NEVER;
  }

  save(item: unknown): Observable<unknown> {
    return NEVER;
  }
}
