import { Injectable } from '@angular/core';
import { TournamentApiInterface } from './tournament.api.interface';
import { BaseService } from '../';
import { Observable } from 'rxjs';
import { CategoryApiInterface } from '../category';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root' })
export class TournamentService extends BaseService implements EntityServiceInterface {
  static createNew(): TournamentApiInterface {
    return {
      type: EntityTypeEnum.Tournaments,
      tournament_id: '',
      id: '',
      name: '',
      order: 0,
      day: null,
      categories: [],
      contestants: [],
      date: null,
      teams: [],
      wekaf: false
    };
  };

  public getAll(): Observable<TournamentApiInterface[]|null> {
    return this.getMany<TournamentApiInterface>(EntityTypeEnum.Tournaments);
  }

  public get(id: string): Observable<TournamentApiInterface | null> {
    return this.getOne<TournamentApiInterface>(EntityTypeEnum.Tournaments, id);
  }

  public save(tournament: TournamentApiInterface): Observable<TournamentApiInterface|null> {
    const clean: TournamentApiInterface = { ...tournament };
    clean.categories = [];
    return this.post<TournamentApiInterface>(EntityTypeEnum.Tournaments, clean);
  }

  public deleteItem(id: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Tournaments, id);
  }

  public storeCategoryOrder(tournament: TournamentApiInterface): Observable<boolean> {
    return this.storeOrder(EntityTypeEnum.Tournaments, tournament.id, tournament.categories?.map((category: CategoryApiInterface) => category.id) || []);
  }
}
