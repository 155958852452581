import {Injectable} from '@angular/core';
import {DivisionApiInterface} from './division.api.interface';
import { BaseService } from '../';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({providedIn: 'root'})
export class DivisionService extends BaseService implements EntityServiceInterface {

  public getAll(categoryId: string): Observable<DivisionApiInterface[]|null> {
    return this.getMany<DivisionApiInterface>(EntityTypeEnum.Divisions, [{key: 'parent_id', value: categoryId}]);
  }

  public get(id: string): Observable<DivisionApiInterface|null> {
    return this.getOne<DivisionApiInterface>(EntityTypeEnum.Divisions, id);
  }

  public save(division: DivisionApiInterface): Observable<DivisionApiInterface|null> {
    return this.post<DivisionApiInterface>(EntityTypeEnum.Divisions, division);
  }

  public deleteItem(division_id: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Divisions, division_id);
  }

}
