// noinspection ES6PreferShortImport

import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ActivateService } from './activate.service';
import { ActivateApiInterface } from './activate.api.interface';

export const ActivateResolver: ResolveFn<ActivateApiInterface | null> = (
  route: ActivatedRouteSnapshot
): Observable<ActivateApiInterface | null> | Promise<ActivateApiInterface | null> | ActivateApiInterface | null => {
  return inject(ActivateService).get(route.paramMap.get('id') || '');
};
