import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[libFlag]'
})
export class FlagDirective implements OnInit {
  @Input() libFlag!: unknown;
  @Input() libFlagSize: 'small' | 'medium' = 'medium';

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'app-flag');
    this.renderer.addClass(this.el.nativeElement, `app-flag-${this.libFlagSize}`);
    this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', `url(assets/flags/${this.libFlag}.svg)`);
  }
}
