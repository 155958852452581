import {Injectable} from '@angular/core';
import { BaseService } from '../';
import { MatchApiInterface } from './match.api.interface';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root'})
export class MatchService extends BaseService implements EntityServiceInterface {
  static createNew(divisionId: string): MatchApiInterface {
    return {
      tournament_id: '',
      type: EntityTypeEnum.Matches,
      id: '',
      name: '',
      order: 0,
      division_id: divisionId,
    };
  };

  public getAll(tournamentId: string): Observable<MatchApiInterface[]|null> {
    return this.getMany<MatchApiInterface>(EntityTypeEnum.Matches, [{key: 'tournament_id', value: tournamentId}]);
  }

  public get(id: string): Observable<MatchApiInterface|null> {
    return this.getOne<MatchApiInterface>(EntityTypeEnum.Matches, id);
  }

  public save(team: MatchApiInterface): Observable<MatchApiInterface|null> {
    const clean: MatchApiInterface = {...team};
    return this.post<MatchApiInterface>(EntityTypeEnum.Matches, clean);
  }

  public deleteItem(teamId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Matches, teamId);
  }

}
