import { Pipe, PipeTransform } from '@angular/core';
import { EntityFieldMetaInterface } from '../interfaces';

@Pipe({
  name: 'formatField',
  standalone: true
})
export class FormatFieldPipe implements PipeTransform {

  transform(value: unknown, field: EntityFieldMetaInterface): string {

    switch (field.type) {
      case 'select':
        return `options.${field.fieldName}.${value}`;
      case 'date':
        return value ? (value as string).split('-').reverse().join('-') : '';
      case 'yes-no':
        return value ? 'term.yes' : 'term.no'
      case 'open-closed':
        return value ? 'term.open' : 'term.closed'
      case 'male-female':
        return value === 'M' ? 'term.male' : 'term.female'
      case 'flag':
        return ''; // return empty string
      default:
        return value ? `${value}`  : '';
    }
  }

}
