import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreakpointService {

  private isMobileSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public isMobile$ = this.isMobileSubject.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 664px)'])
      .subscribe((result: BreakpointState) => {
        this.isMobileSubject.next(result.matches);
      });
  }
}
