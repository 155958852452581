import { BaseService } from '../';
import { Injectable } from '@angular/core';
import { UserApiInterface } from './user.api.interface';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root'})
export class UserService extends BaseService implements EntityServiceInterface {

  public getAll(tournamentId: string): Observable<UserApiInterface[]|null> {
    return this.getMany<UserApiInterface>(EntityTypeEnum.Users, [{key: 'tournament_id', value: tournamentId}]);
  }

  public getAllTeam(teamId: string): Observable<UserApiInterface[]|null> {
    return this.getMany<UserApiInterface>(
      EntityTypeEnum.Users, [{key: 'team_id', value: teamId}]
    );
  }

  public get(id: string): Observable<UserApiInterface|null> {
    return this.getOne<UserApiInterface>(EntityTypeEnum.Users, id);
  }

  public save(team: UserApiInterface): Observable<UserApiInterface|null> {
    const clean: UserApiInterface = {...team};
    return this.post<UserApiInterface>(EntityTypeEnum.Users, clean);
  }

  public deleteItem(teamId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Users, teamId);
  }

}
