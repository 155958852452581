import { Directive, HostListener, Input } from '@angular/core';
import { EntityInterface, NavigateService } from '../../core';

@Directive({
  standalone: true,
  selector: '[libEntityRouterlink]'
})
export class EntityRouterlinkDirective {
  @Input() libEntityRouterlink!: EntityInterface;

  @HostListener('click')
  onClick(){
    this.navigateService.navigateToEntity(this.libEntityRouterlink)
  }
  constructor(
    private navigateService:NavigateService
  ) {
  }
}
