import { Injectable, inject } from '@angular/core';
import { environment } from '@environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, map, Observable, of, take, tap } from 'rxjs';
import {
  MessageService,
  EntityInterface,
  NavigateService,
  StorageService,
  TokenInterface,
  ApiResponseInterface,
  EntityTypeEnum
} from '@libs/core';

@Injectable({ providedIn: 'root' })
export abstract class BaseService {

  private httpClient: HttpClient = inject(HttpClient);
  private navigateService: NavigateService = inject(NavigateService);

  protected constructor(
    private messageService: MessageService
  ) {
  }


  public get options(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': StorageService.getToken()?.access_token || ''
      })
    };
  }

  public async getToken(email: string, password: string): Promise<TokenInterface | null> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`${email}:${password}`)
      })
    };

    return firstValueFrom(
      this.httpClient.get<ApiResponseInterface<TokenInterface>>(
        `${environment.apiUrl}account/token`,
        httpOptions
      ).pipe(
        map((response: ApiResponseInterface<TokenInterface>): TokenInterface | null => response.data),
        tap((token: TokenInterface | null): void => {
          StorageService.storeToken(token);
        })
      )
    );
  }

  public async sendLoginMail(email: string): Promise<boolean | null> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`${email}:`)
      })
    };

    return firstValueFrom(
      this.httpClient.get<ApiResponseInterface<boolean>>(
        `${environment.apiUrl}account/forgot`,
        httpOptions
      ).pipe(
        map((response: ApiResponseInterface<boolean>): boolean => response.code === '200'),
        catchError(() => of(null))
      )
    );
  }

  public logout(): void {
    this.httpClient.get(
      `${environment.apiUrl}account/logout`,
      this.options
    ).subscribe(() => localStorage.removeItem('token'));
  }


  public abstract save(item: unknown): Observable<unknown>

  public abstract deleteItem(id: string): Observable<boolean>

  protected getMany<T extends EntityInterface>(path: EntityTypeEnum, parent: {
    key: 'tournament_id' | 'team_id' | 'parent_id' | 'wekaf' | 'deep',
    value: string | boolean
  }[] | null = null): Observable<T[] | null> {
    let params: HttpParams = new HttpParams();
    if (parent?.length) {
      parent.forEach(({ key, value }) =>
        params = params.append(key, value)
      );
    }
    return this.httpClient.get<ApiResponseInterface<T[]>>(
      `${environment.apiUrl}${path}`,
      { ...this.options, ...{ params } }
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<T[]>) => this.handleSuccess<T[]>(response)),
      catchError(this.handleError<T[]>),
      map((response: ApiResponseInterface<T[]>): T[] | null => {
        if (response.data?.length) {
          response.data.sort((a: T, b: T): number => a.order - b.order || a.name.localeCompare(b.name));
          response.data.forEach((entity) => entity.type = path);
        }
        return response.data;
      })
    );
  }

  protected getOne<T extends EntityInterface>(path: EntityTypeEnum, id: string, parent: {
    key: 'deep',
    value: string | boolean
  } | null = null): Observable<T | null> {
    let params: HttpParams = new HttpParams().set('id', id);
    if (parent) {
      params = params.append(parent.key, parent.value);
    }
    return this.httpClient.get<ApiResponseInterface<T>>(
      `${environment.apiUrl}${path}`,
      { ...this.options, ...{ params } }
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<T>) => this.handleSuccess<T>(response)),
      catchError(this.handleError<T>),
      map((response: ApiResponseInterface<T>): T | null => {
        if (response.data) {
          response.data.type = path;
        }
        return response.data;
      })
    );
  }

  protected post<T extends EntityInterface>(path: EntityTypeEnum, item: T): Observable<T | null> {
    return this.httpClient.post<ApiResponseInterface<T>>(
      `${environment.apiUrl}${path}`,
      item,
      { ...this.options }
    ).pipe(
      take(1),
      catchError(this.handleError<T>),
      tap((response: ApiResponseInterface<T>) => {
        this.handleSuccess<T>(response);
        if (response.success) {
          this.messageService.displayMessage({
            message: 'message.api.update.success',
            style: 'info'
          });
        }
      }),
      map((response: ApiResponseInterface<T>): T | null => {
        if (response.data) {
          response.data.type = path;
        }
        return response.data;
      })
    );
  }

  protected postMany(path: string, data: unknown, parent: {
    key: string,
    value: string
  } | null = null): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    if (parent) {
      params = params.append(parent.key, parent.value);
    }
    return this.httpClient.post<ApiResponseInterface<void>>(
      `${environment.apiUrl}${path}`,
      data,
      { ...this.options, ...{ params } }
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<void>) => this.handleSuccess<void>(response)),
      catchError(this.handleError<void>),
      map((response: ApiResponseInterface<void>): boolean => {
        return Boolean(response.success);
      })
    );
  }

  protected postFile<T>(path: string, formData: FormData): Observable<T[] | null> {
    const options = {
      headers: new HttpHeaders({
        'X-Token': StorageService.getToken()?.access_token || '',
        'enctype': 'multipart/form-data',
        'Accept': 'application/json'
      })
    };

    return this.httpClient.post<ApiResponseInterface<T[]>>(
      `${environment.apiUrl}${path}`,
      formData,
      options
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<T[]>) => this.handleSuccess<T>(response)),
      catchError(this.handleError<T[]>),
      map((response: ApiResponseInterface<T[]>) => {
        return response.data;
      })
    );
  }

  protected delete(path: string, id: string, parent_id: string | null = null): Observable<boolean> {
    let params: HttpParams = new HttpParams()
      .set('id', id);
    if (parent_id) {
      params = params.append('parent_id', parent_id || '');
    }
    return this.httpClient.delete<ApiResponseInterface<void>>(
      `${environment.apiUrl}${path}`,
      { ...this.options, ...{ params } }
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<void>) => {
        this.handleSuccess<void>(response);
        if (response.success) {
          this.messageService.displayMessage({
            message: 'message.api.delete.success',
            style: 'info'
          });
        }
      }),
      catchError(this.handleError<void>),
      map((response: ApiResponseInterface<void>): boolean => {
        return Boolean(response.success);
      })
    );
  }

  protected storeOrder(path: string, id: string, order: string[]): Observable<boolean> {
    return this.httpClient.patch<ApiResponseInterface<void>>(
      `${environment.apiUrl}${path}`,
      {
        id,
        order
      },
      { ...this.options }
    ).pipe(
      take(1),
      tap((response: ApiResponseInterface<void>) => this.handleSuccess<void>(response)),
      catchError(this.handleError<void>),
      map((response: ApiResponseInterface<unknown>): boolean => {
        return Boolean(response.success);
      })
    );
  }

  private handleError = <T>(error: Record<string, string>): Observable<ApiResponseInterface<T>> => {
    this.messageService.displayMessage({
      message: error['message'],
      style: 'error'
    });
    const response: ApiResponseInterface<T> = {
      success: false,
      data: null,
      code: '',
      state: '',
      message: `${error['message']}`
    };
    return of(response);
  };

  private handleSuccess<T>(response: ApiResponseInterface<T> | ApiResponseInterface<T[]>): Observable<ApiResponseInterface<T> | ApiResponseInterface<T[]>> {
    if (response.data && Array.isArray(response.data) && response.data.length) {
      // if (Object.keys(response.data[0]).includes('order')) {
      //   response.data.sort((a, b) => a.order - b.order);
      // }
    }
    response.success = response.code === '200';
    if (!response.success) {
      switch (response.code) {
        case '401':
          StorageService.clearToken();
          this.navigateService.toLogin();
          this.messageService.displayMessage({
            message: response.message || 'message.api.unauthorized',
            style: 'error'
          });
          break;
        case '403':
          this.messageService.displayMessage({
            message: response.message || 'message.api.forbidden',
            style: 'error'
          });
          break;
        case '400':
          this.messageService.displayMessage({
            message: response.message || 'message.api.bad-request',
            style: 'error'
          });
          break;
        default:
          this.messageService.displayMessage({
            message: response.message || 'message.api.error',
            style: 'error'
          });
      }
    }
    return of(response);
  }
}
