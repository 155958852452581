import { Injectable } from '@angular/core';
import { environment } from '@environments';

@Injectable({ providedIn: 'root' })
export class LoggingService {

  static log(...data: unknown[]){
    if (environment.logging) {
      console.log(data);
    }
  }
  static warn(...data: unknown[]){
    if (environment.logging) {
      console.warn(data);
    }
  }
  static error(...data: unknown[]){
    if (environment.logging) {
      console.error(data);
    }
  }
}
