// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageInterface } from '../interfaces/message.interface';

@Injectable({ providedIn: 'root' })
export class MessageService {

  private messageSubject: Subject<MessageInterface> = new Subject<MessageInterface>();
  public messages$ = this.messageSubject.asObservable();

  public displayMessage(message: MessageInterface) {
    this.messageSubject.next(message);
  }

}
