import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { CategoryService } from './category.service';
import { CategoryApiInterface } from './category.api.interface';

export const CategoryResolver: ResolveFn<CategoryApiInterface | null> = (
  route: ActivatedRouteSnapshot): Observable<CategoryApiInterface | null> => {
  return inject(CategoryService).getDeep(route.paramMap.get('id') || '');
};
