import { ResolveFn } from '@angular/router';
import { TournamentApiInterface } from './tournament.api.interface';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { TournamentService } from './tournament.service';

export const TournamentsResolver: ResolveFn<TournamentApiInterface[] | null>
  = (): Observable<TournamentApiInterface[] | null> => {
  return inject(TournamentService).getAll();
};
