import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { TeamService } from './team.service';
import { TeamApiInterface } from './team.api.interface';

export const TeamResolver: ResolveFn<TeamApiInterface | null> = (
  route: ActivatedRouteSnapshot
): Observable<TeamApiInterface | null> | Promise<TeamApiInterface | null> | TeamApiInterface | null => {
  return inject(TeamService).get(route.paramMap.get('id') || '');
};
