import {Injectable} from '@angular/core';
import {CategoryApiInterface} from './category.api.interface';
import { BaseService } from '../';
import { EntityServiceInterface, EntityTypeEnum, MatchTypeEnum } from '@libs/core';
import { Observable } from 'rxjs';
import { DivisionApiInterface } from '../division';

@Injectable({providedIn: 'root'})
export class CategoryService extends BaseService implements EntityServiceInterface {
  static createNew(tournamentId: string): CategoryApiInterface {
    return {
      type: EntityTypeEnum.Categories,
      id: '',
      name: '',
      tournament_id: tournamentId,
      match_type_id: MatchTypeEnum.COMPETITION,
      order: 0,
      contestants: [],
      divisions: [],
    };
  };

  public getAll(tournamentId: string): Observable<CategoryApiInterface[]|null> {
    return this.getMany<CategoryApiInterface>(EntityTypeEnum.Categories, [{key: 'tournament_id', value: tournamentId}]);
  }

  public getAllDeep(tournamentId: string): Observable<CategoryApiInterface[]|null> {
    return this.getMany<CategoryApiInterface>(EntityTypeEnum.Categories, [{key: 'tournament_id', value: tournamentId},{key: 'deep', value: true}]);
  }

  public get(id: string): Observable<CategoryApiInterface|null> {
    return this.getOne<CategoryApiInterface>(EntityTypeEnum.Categories, id);
  }

  public getDeep(id: string): Observable<CategoryApiInterface|null> {
    return this.getOne<CategoryApiInterface>(EntityTypeEnum.Categories, id, {key: 'deep', value: true});
  }

  public save(category: CategoryApiInterface): Observable<CategoryApiInterface|null> {
    return this.post<CategoryApiInterface>(EntityTypeEnum.Categories, category);
  }

  public deleteItem(categoryId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Categories, categoryId);
  }

  public storeDivisionOrder(category: CategoryApiInterface): Observable<boolean> {
    return this.storeOrder(EntityTypeEnum.Categories, category.id, category.divisions?.map((division: DivisionApiInterface) => division.id) || []);
  }

  public getAllTournament(tournamentId: string): Observable<CategoryApiInterface[]|null> {
    return this.getMany<CategoryApiInterface>(
      EntityTypeEnum.Categories, [{key: 'tournament_id', value: tournamentId}]
    );
  }

  public addWekafCategories(tournamentId: string): Observable<CategoryApiInterface[]|null> {
    return this.getMany<CategoryApiInterface>(
      EntityTypeEnum.Categories, [
        {key: 'tournament_id', value: tournamentId},
        {key: 'wekaf', value: true},
      ]
    );
  }

}
