import { BaseService } from '../';
import { Injectable } from '@angular/core';
import { TeamApiInterface } from './team.api.interface';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root'})
export class TeamService extends BaseService implements EntityServiceInterface {
  static createNew(tournamentId: string): TeamApiInterface {
    return {
      type: EntityTypeEnum.Teams,
      id: '',
      name: '',
      order: 0,
      tournament_id: tournamentId,
      country_code: '',
    };
  };

  public getAll(tournamentId: string): Observable<TeamApiInterface[]|null> {
    return this.getMany<TeamApiInterface>(EntityTypeEnum.Teams, [{key: 'tournament_id', value: tournamentId}]);
  }

  public get(id: string): Observable<TeamApiInterface|null> {
    return this.getOne<TeamApiInterface>(EntityTypeEnum.Teams, id);
  }

  public save(team: TeamApiInterface): Observable<TeamApiInterface|null> {
    const clean: TeamApiInterface = {...team};
    return this.post<TeamApiInterface>(EntityTypeEnum.Teams, clean);
  }

  public deleteItem(teamId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Teams, teamId);
  }

}
