export enum EntityTypeEnum {
    Tournaments = 'tournaments',
    Categories = 'categories',
    Teams = 'teams',
    Users = 'users',
    Rings = 'rings',
    Matches = 'matches',
    Invitations = 'invitations',
    Invite = 'invite',
    Divisions = 'divisions',
    Contestants = 'contestants',
    ContestantsCategories = 'contestants-categories',
    ContestantsUpload = 'contestants-upload',
    ContestantsBulkUpload = 'contestants-bulk-upload',
    Activate = 'activate',
}
