import { Injectable } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { ActivateApiInterface, BaseService } from '../../datalayer';
import { EntityTypeEnum } from '@libs/core';

@Injectable({ providedIn: 'root' })
export class ActivateService extends BaseService {

  public get(invite_id: string): Observable<ActivateApiInterface|null> {
    return this.getOne<ActivateApiInterface>(EntityTypeEnum.Activate, invite_id);
  }

  save(activation: ActivateApiInterface): Observable<ActivateApiInterface | null> {
    return this.post<ActivateApiInterface>(EntityTypeEnum.Activate,activation);
  }

  deleteItem(id: string): Observable<boolean> {
    return NEVER;
  }


}
