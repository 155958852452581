// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ModalService {

  constructor(
    private router: Router,
  ) {
  }

  openModal(name: string) {
    this.router.navigate([{ outlets: { modal: name } }]);
  }

  closeModal() {
    this.router.navigate([{ outlets: { modal: null } }]);
  }
}
