import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ContestantCategoryApiInterface } from './contestant-category.api.interface';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';
import { BaseService } from '../core';

@Injectable({providedIn: 'root'})
export class ContestantCategoryService extends BaseService implements EntityServiceInterface {

  deleteItem(id: string): Observable<boolean> {
        throw new Error('Method not implemented.');
    }

  public save(data: Partial<ContestantCategoryApiInterface>): Observable<ContestantCategoryApiInterface|null> {
    return this.post<ContestantCategoryApiInterface>(EntityTypeEnum.ContestantsCategories, data as ContestantCategoryApiInterface);
  }

}
