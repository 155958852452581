export enum UserTypeEnum {
  /** @member ADMIN */
  /** Has access to all tournaments and can add tournament organizers */
  ADMIN = 'ADMIN',
  /** @member TOURNAMENT_ADMIN */
  /** Can create tournaments */
  TOURNAMENT_ADMIN = 'TOURNAMENT_ADMIN',
  /** @member TOURNAMENT_MANAGER */
  /** Can manage assigned tournaments  */
  TOURNAMENT_MANAGER = 'TOURNAMENT_MANAGER',
  /** @member TEAM_CAPTAIN */
  /** Can manage assigned team(s)  */
  TEAM_CAPTAIN = 'TEAM_CAPTAIN',
  /** @member CONTESTANT */
  /** Can see tournament info, assigned team details and competition schedules */
  CONTESTANT = 'CONTESTANT',
  JUDGE = 'JUDGE',
  VISITOR = 'VISITOR',
}
