import { Injectable } from '@angular/core';
import { RingApiInterface } from './ring.api.interface';
import { Observable } from 'rxjs';
import { EntityServiceInterface, EntityTypeEnum } from '@libs/core';
import { BaseService } from '../core';

@Injectable({ providedIn: 'root' })
export class RingService extends BaseService implements EntityServiceInterface {
  static createNew(tournamentId: string): RingApiInterface {
    return {
      type: EntityTypeEnum.Rings,
      id: '',
      name: '',
      order: 0,
      tournament_id: tournamentId
    };
  };

  public getAll(tournamentId: string): Observable<RingApiInterface[] | null> {
    return this.getMany<RingApiInterface>(EntityTypeEnum.Rings, [{ key: 'tournament_id', value: tournamentId }]);
  }

  public get(id: string): Observable<RingApiInterface | null> {
    return this.getOne<RingApiInterface>(EntityTypeEnum.Rings, id);
  }

  public save(team: RingApiInterface): Observable<RingApiInterface | null> {
    const clean: RingApiInterface = { ...team };
    return this.post<RingApiInterface>(EntityTypeEnum.Rings, clean);
  }

  public deleteItem(teamId: string): Observable<boolean> {
    return this.delete(EntityTypeEnum.Rings, teamId);
  }

}
