// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { TokenInterface } from '../interfaces/token.interface';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class StorageService {

  //-------------------------------------

  static tokenKey = 'token';

  public static storeToken(token: TokenInterface | null) {
    if (token) {
      this.storeObjectInStorage<TokenInterface>(StorageService.tokenKey, token);
    } else {
      this.removeFromStorage(StorageService.tokenKey);
    }
  }

  public static getToken(): TokenInterface | null {
    return this.getObjectFromStorage<TokenInterface>(StorageService.tokenKey);
  }

  public static clearToken() {
    return this.removeFromStorage(StorageService.tokenKey);
  }

  public static get isTokenValid(): boolean {
    const token = StorageService.getToken();
    LoggingService.log('StorageService.isTokenValid','token',token);
    if (!token) return false;
    const expires_at: string = token.expires_at;
    if (!expires_at) return false;
    const expiresDate: Date = new Date(expires_at);
    const isExpired = expiresDate.getTime() < (new Date()).getTime();
    if (isExpired) {
      StorageService.clearToken();
    }
    LoggingService.log('StorageService.isTokenValid','isExpired',isExpired);
    return !isExpired;
  }


  //-------------------------------------

  static tournamentsNavigationStateKey = 'tournamentsNavigationState';

  public static storeTournamentsNavigationState(opened: boolean) {
    StorageService.storeBooleanInStorage(StorageService.tournamentsNavigationStateKey, opened);
  }

  public static getTournamentsNavigationState(): boolean {
    return StorageService.getBooleanFromStorage(StorageService.tournamentsNavigationStateKey);
  }

  //-------------------------------------

  static langKey = 'lang';

  public static storeLang(lang: string) {
    StorageService.storeStringInStorage(StorageService.langKey, lang);
  }

  public static getLang(): string | null {
    return StorageService.getStringFromStorage(StorageService.langKey);
  }

  //////////////////////////////////////////////////////////////////

  protected static storeBooleanInStorage(key: string, value: boolean) {
    StorageService.storeStringInStorage(key, value ? 'true' : 'false');
  }

  protected static storeObjectInStorage<T>(key: string, value: T) {
    StorageService.storeStringInStorage(key, JSON.stringify(value));
  }

  protected static storeStringInStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  protected static getObjectFromStorage<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data) as T;
    }
    return null;
  }

  protected static getStringFromStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  protected static getBooleanFromStorage(key: string): boolean {
    const data = localStorage.getItem(key);
    return data === 'true';
  }

  protected static removeFromStorage(key: string) {
    localStorage.removeItem(key);
  }

  protected static clearStorage() {
    localStorage.clear();
  }
}
