import { Injectable } from '@angular/core';
import { StorageService, TokenInterface, UserTypeEnum } from '@libs/core';
// noinspection ES6PreferShortImport
import { BaseService } from '../core/services';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isLoggedIn: any;

  constructor(
    private apiService: BaseService
  ) {
  }

  public get accessToken(): string | undefined {
    return StorageService.getToken()?.access_token;
  }

  public get username(): string | undefined {
    return StorageService.getToken()?.name;
  }

  public static get userType(): UserTypeEnum | undefined {
    return StorageService.getToken()?.user_type;
  }

  public static isUserOneOfGivenTypes(userTypes?: UserTypeEnum[]): boolean {
    if (!userTypes) {
      return true;
    }
    const currentUserType = StorageService.getToken()?.user_type;
    if (!currentUserType) {
      return false;
    }
    return userTypes.includes(currentUserType);
  }

  public async getToken(email: string, password: string): Promise<TokenInterface | null> {
    return this.apiService.getToken(email, password);
  }

  public async sendLoginMail(email: string): Promise<boolean | null> {
    return this.apiService.sendLoginMail(email);
  }

  public static get isAuthenticated(): boolean {
    return StorageService.isTokenValid;
  }

  public logout(): void {
    this.apiService.logout();
  }

}
